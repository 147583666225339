import { Box, HStack } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { AiFillTwitterCircle } from "react-icons/ai"
import { FiInstagram, FiYoutube } from "react-icons/fi"
import { RiFacebookCircleFill } from "react-icons/ri"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useDefaultClient } from "~/hooks/useDefaultClient"
import SocialMediaLink from "../SocialMediaLInk"
import FormHeading from "../checkout/FormHeading"

export interface Props {}

const OrderCompleteSocialMedia: React.FC<Props> = () => {
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl, primaryColor } =
    useApplicationState()
  const {
    client: { supportEmail },
  } = useDefaultClient()

  const { t } = useTranslation()

  const iconProps = {
    bg: "white",
    color: "gray.600",
    transition: "none",
    hover: {
      color: primaryColor,
    },
    size: "42px",
  }
  if (!facebookUrl && !instagramUrl && !twitterUrl && !youtubeUrl) {
    return null
  }
  return (
    <Box pt={8}>
      <FormHeading mb={2}>
        {t("components.order-complete.OrderCompleteSocialMediaBlock.Title")}
      </FormHeading>
      <Box
        dangerouslySetInnerHTML={{
          __html: t(
            "components.order-complete.OrderCompleteSocialMediaBlock.Body",
            {
              supportEmail,
            }
          ),
        }}
      ></Box>
      <HStack
        spacing={4}
        alignItems="left"
        justifyContent={{ base: "center", md: "flex-start" }}
        py={2}
      >
        {facebookUrl && (
          <SocialMediaLink
            icon={RiFacebookCircleFill}
            link={facebookUrl}
            {...iconProps}
          />
        )}

        {instagramUrl && (
          <SocialMediaLink
            icon={FiInstagram}
            link={instagramUrl}
            {...iconProps}
          />
        )}

        {twitterUrl && (
          <SocialMediaLink
            icon={AiFillTwitterCircle}
            link={twitterUrl}
            {...iconProps}
          />
        )}

        {youtubeUrl && (
          <SocialMediaLink icon={FiYoutube} link={youtubeUrl} {...iconProps} />
        )}
      </HStack>
    </Box>
  )
}

export default OrderCompleteSocialMedia
