import { Box, Flex, HStack, Image, useMediaQuery } from "@chakra-ui/react"
import React from "react"
import QRCode from "react-qr-code"
import { useApplicationState } from "~/hooks/useApplicationState"
import AppStoreButtons from "../common/AppStoreButtons"

export interface Props {}

const OrderCompleteAppInstall: React.FC<Props> = () => {
  const {
    iosStoreUrl,
    androidStoreUrl,
    language,
    blockOrderSummaryAppInstallQrCode,
    blockOrderSummaryAppInstallQrCodeUrl,
    blockOrderSummaryAppInstallAppScreenImage,
    blockOrderSummaryAppInstallContent,
  } = useApplicationState()

  // Using object here for props because TS complains that `viewBox` is not assignable, even though it is
  const qrCodeProps = {
    value: blockOrderSummaryAppInstallQrCodeUrl,
    size: 256,
    viewBox: "0 0 256 256",
    style: {
      height: "auto",
      maxWidth: "112px",
      width: "100%",
    },
  }

  const isMobileQuery = useMediaQuery([
    "(max-width: 637px)",
    "(min-width: 767px) and (max-width: 1200px)",
  ])

  const isMobile = isMobileQuery.some(queryMatches => queryMatches === true)

  const showQRCode =
    Boolean(blockOrderSummaryAppInstallQrCode) &&
    Boolean(blockOrderSummaryAppInstallQrCodeUrl) &&
    !isMobile

  return (
    <HStack
      spacing={0}
      alignItems="stretch"
      shadow="md"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="lg"
      flexDirection={isMobile ? "column" : "row"}
      mb={8}
    >
      {blockOrderSummaryAppInstallAppScreenImage && (
        <Flex
          w={isMobile ? "full" : "50%"}
          flexShrink={0}
          bgImage="url('images/OrderSummaryAppInstallBackground.jpg')"
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
          pt={8}
          pl={4}
          pr={4}
          mb={isMobile ? -32 : 0}
          borderTopLeftRadius="lg"
          borderBottomLeftRadius="lg"
          borderTopRightRadius={isMobile ? "lg" : "none"}
        >
          <Image
            src={blockOrderSummaryAppInstallAppScreenImage.path}
            mx="auto"
            mt="auto"
            width={60}
            maxW={isMobile ? "full" : 60}
          />
        </Flex>
      )}
      <Flex
        flexDir="column"
        alignItems="stretch"
        flexGrow={1}
        justifyContent="center"
        width={isMobile ? "full" : "none"}
        bgColor="white"
        borderRadius={isMobile ? "none" : "lg"}
        borderBottomRadius="lg"
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: blockOrderSummaryAppInstallContent,
          }}
          p={4}
        />
        <HStack spacing={4} px={4} pt={0} pb={4} justifyContent="start">
          {showQRCode && (
            <Box>
              <QRCode {...qrCodeProps} />
            </Box>
          )}
          <Box flexGrow={1} maxW={isMobile ? "full" : 36}>
            <AppStoreButtons
              justify="center"
              appStoreUrl={iosStoreUrl}
              googlePlayUrl={androidStoreUrl}
              language={language}
            />
          </Box>
        </HStack>
      </Flex>
    </HStack>
  )
}

export default OrderCompleteAppInstall
