import {
  Box,
  Heading,
  HStack,
  Image,
  Link,
  Progress,
  Text,
} from "@chakra-ui/react"
import { BlockOrderCompleteEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useContentCrossSell } from "~/hooks/useContentCrossSell"
import FormHeading from "../checkout/FormHeading"

interface Props {
  config: BlockOrderCompleteEntityHydrated
  upsellContentId: string
}

const OrderCompleteUpsell: React.FC<Props> = ({ config, upsellContentId }) => {
  const { t } = useTranslation()
  const { crossSell, isLoading } = useContentCrossSell(upsellContentId)
  const { primaryColor } = useApplicationState()

  return (
    <Box>
      {isLoading && <Progress size="sm" isIndeterminate />}
      {crossSell.length > 0 && (
        <FormHeading id="upsell-title">
          {config.upsellTitle ||
            t("components.checkout.OrderCompleteUpsell.Heading")}
        </FormHeading>
      )}
      {!isLoading &&
        crossSell.map((crossSell, index) => (
          <Box
            key={index}
            shadow="md"
            borderRadius="md"
            borderWidth={1}
            borderStyle="solid"
            borderColor="gray.200"
            mb="4"
            _hover={{
              boxShadow: "xl",
              borderColor: primaryColor ?? "primary.500",
            }}
          >
            <Link
              href={crossSell.actionButtonUrl}
              _hover={{ textDecoration: "none" }}
            >
              <HStack spacing={{ base: 2, sm: 4, md: 2, xl: 4 }}>
                <Image
                  src={crossSell.image?.url}
                  alt={crossSell.imageAltText}
                  borderTopLeftRadius="md"
                  borderBottomLeftRadius="md"
                  w="50%"
                />
                <Box textAlign="initial" pr={{ base: 2, sm: 4, md: 2, xl: 4 }}>
                  <Heading
                    className="upsell-content-title"
                    as="h4"
                    size="md"
                    fontSize={{
                      base: "md",
                      sm: "xl",
                      md: "md",
                      lg: "lg",
                      xl: "xl",
                    }}
                  >
                    {crossSell.textTitle}
                  </Heading>
                  <Text
                    className="upsell-content-text"
                    fontSize={{
                      base: "sm",
                      sm: "md",
                      md: "xs",
                      lg: "sm",
                      xl: "md",
                    }}
                  >
                    {crossSell.textBody}
                  </Text>
                </Box>
              </HStack>
            </Link>
          </Box>
        ))}
    </Box>
  )
}

export default OrderCompleteUpsell
