import { Box, Button, Flex } from "@chakra-ui/react"
import { BlockOrderCompleteEntityHydrated } from "@jackfruit/common"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"
import { Address } from "~/interfaces/entities/Address"
import OrderCompleteAppInstall from "../order-complete/OrderCompleteAppInstall"
import OrderCompleteDetails from "../order-complete/OrderCompleteDetails"
import OrderCompleteSocialMedia from "../order-complete/OrderCompleteSocialMedia"
import OrderCompleteUpsell from "../order-complete/OrderCompleteUpsell"
import Wrapper from "../Wrapper"

export interface Props {
  config: BlockOrderCompleteEntityHydrated
}

const OrderCompleteBlock: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const { lastOrderId: currentOrderId, blockOrderSummaryAppInstallEnabled } =
    useApplicationState()
  const { order: placedOrder } = useOrder(currentOrderId)

  const { cart, store } = placedOrder
  const { fulfillment, address: cartAddress } = cart

  const isPickup = fulfillment === "pickup"
  const hasUpsell =
    Boolean(config.upsellEnabled) && Boolean(config.upsellContentId)

  const orderAddress: Address = isPickup
    ? {
        name: store?.name,
        line1: store?.address || "",
        line2: store?.addressLine2,
        city: store?.city || "",
        state: store?.state || "",
        country: store?.country || "",
        postcode: store?.postcode || "",
      }
    : cartAddress

  const isFakeOrder = placedOrder.id === 1

  return (
    <Wrapper pt={0} pb={16} px={4}>
      <Flex
        justifyContent="center"
        alignItems="start"
        flexDirection={{ base: "column", md: "row" }}
        textAlign={{ base: "center", md: "start" }}
      >
        <Box
          w={{ base: "100%", md: "60%", lg: "66.66%" }}
          maxW="52rem"
          pr={hasUpsell ? { base: 0, md: 8, lg: 16 } : 0}
        >
          {Boolean(blockOrderSummaryAppInstallEnabled) && (
            <OrderCompleteAppInstall />
          )}
          {!isFakeOrder && (
            <OrderCompleteDetails
              order={placedOrder}
              orderAddress={orderAddress}
              store={store}
            />
          )}
          <Button
            w={{ base: "100%", sm: 60 }}
            colorScheme="primary"
            color="primary.text"
            id="continue-shopping"
            as={Link}
            to={"/"}
            mt={8}
          >
            {t("components.blocks.OrderCompleteBlock.Continue")}
          </Button>
          {!hasUpsell && <OrderCompleteSocialMedia />}
        </Box>

        {hasUpsell && (
          <Box
            w={{ base: "100%", md: "40%", lg: "33.34%" }}
            mt={{ base: "8", md: 0 }}
          >
            <OrderCompleteUpsell
              config={config}
              upsellContentId={config.upsellContentId}
            />
            <OrderCompleteSocialMedia />
          </Box>
        )}
      </Flex>
    </Wrapper>
  )
}

export default OrderCompleteBlock
